import Icons from 'Icons';
import DataTableFilters, {
    DataTableFilterConfig,
} from 'components/DataTableFilters/DataTableFilters';
import SearchFilter from 'components/DataTableFilters/Filters/SearchFilter';
import MyButton, { MyButtonLinkNewTab } from 'components/MyButton/MyButton';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import OrderProductAddModal from 'features/orders/components/OrderProductAddModal/OrderProductAddModal';
import { OrderProductRow } from 'features/orders/components/OrderProductRow/OrderProductRow';
import {
    orderProductSort,
    useProductGroupOptions,
} from 'features/orders/helpers/orderProductHelper';
import { validateProducts } from 'features/orders/helpers/orderValidationHelper';
import { OrderWindow } from 'features/orders/models/OrderWindow';
import { OrderWindowProduct } from 'features/orders/models/OrderWindowProduct';
import PurchaseOrderReviewModal from 'features/purchases/components/PurchaseOrderReviewModal/PurchaseOrderReviewModal';
import PurchaseOrderStatus from 'features/purchases/enums/PurchaseOrderStatus';
import { PurchaseOrderDetail } from 'features/purchases/models/PurchaseOrderDetail';
import { selectIsMultiSupplier, selectManufacturer } from 'features/settings/settings.slice';
import { useBreakpoints } from 'providers/Breakpoints';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import coalesceClassNames from 'utils/coalesceClassNames';
import { formatDateRelative } from 'utils/dateHelpers';
import { formatCurrency } from 'utils/helpers';
import PurchaseOrderEditModal from '../PurchaseOrderEditModal/PurchaseOrderEditModal';
import PurchaseOrderStatusBadge from '../PurchaseOrderStatusBadge/PurchaseOrderStatusBadge';
import './PurchaseOrderDetailTab.scss';

export default function PurchaseOrderDetailTab({
    model,
    windows,
}: {
    model: PurchaseOrderDetail;
    windows: OrderWindow[];
}) {
    const breakpoints = useBreakpoints();

    const [showEditModal, setShowEditModal] = useState(false);
    const [showReviewModal, setShowReviewModal] = useState(false);

    /** Total number of products */
    const totalQuantity = model?.totalQuantity ?? 0;

    /** Calculated total applied to this order (ignorning overrideTotal) */
    const totalPrice = model?.totalCost ?? 0;

    const isMultiSupplier = useAppSelector(selectIsMultiSupplier);
    const manufacturerName = useAppSelector(selectManufacturer(model.manufacturerId))?.name;

    return (
        <div className="PurchaseOrderDetailTab">
            <div className="PurchaseOrderDetailTab__DetailsPanel">
                <PropertyContainer layout="table">
                    {isMultiSupplier && (
                        <PropertyDisplay
                            label="Supplier"
                            value={manufacturerName}
                        />
                    )}

                    <PropertyDisplay
                        label="Sales rep"
                        value={model.salesRep}
                    />

                    <PropertyDisplay
                        className={coalesceClassNames(
                            'PurchaseOrderDetailTab__DetailsPanel__Sidemark',
                            !model.isReadOnly && 'editable',
                        )}
                        label="Sidemark"
                        value={model.sidemark}
                        verticalAlign="top"
                    />

                    <PropertyDisplay
                        label="Notes"
                        verticalAlign="top"
                        value={model.notes}
                    />

                    <PropertyDisplay
                        label="Status"
                        verticalAlign="top"
                        value={
                            <div className="PurchaseOrderDetailTab__StatusBadges">
                                {model?.isArchived === true && <ArchivedBadge />}
                                <PurchaseOrderStatusBadge status={model.status} />
                                <LinkToSalesOrder model={model} />
                            </div>
                        }
                    />

                    {model.status === PurchaseOrderStatus.Submitted && (
                        <>
                            <PropertyDisplay
                                label="Required Date"
                                value={
                                    model.context.requiredDate
                                        ? formatDateRelative(model.context.requiredDate)
                                        : null
                                }
                            />
                            <PropertyDisplay
                                label="Shipping Method"
                                value={model.context.shippingMethod?.name}
                            />
                            <PropertyDisplay
                                label="Shipping Address"
                                verticalAlign="top"
                                value={model.context.shippingAddress}
                            />
                        </>
                    )}
                </PropertyContainer>

                {!model.isReadOnly && (
                    <MyButton
                        className="PurchaseOrderDetailTab__DetailsPanel__EditButton"
                        label="Edit"
                        IconLeft={Icons.Edit}
                        buttonType="Hollow"
                        onClick={() => setShowEditModal(true)}
                    />
                )}
            </div>

            <ProductList
                order={model}
                windows={windows}
                isReadOnly={model.isReadOnly}
            />

            {totalQuantity > 0 && (
                <div className="PurchaseOrderDetailTab__Footer">
                    <div className="PurchaseOrderDetailTab__Footer__Content">
                        <div className="PurchaseOrderDetailTab__Footer__Content__TotalQuantity">
                            {totalQuantity} {totalQuantity === 1 ? 'product' : 'products'}, total
                        </div>

                        <div className="PurchaseOrderDetailTab__Footer__Content__Price">
                            {formatCurrency(totalPrice)}
                        </div>
                    </div>
                    {model.status === PurchaseOrderStatus.Submitted ? (
                        <MyButton
                            label="View PDF"
                            buttonType="Hollow"
                            href={model.context.orderDocument?.url}
                            disabled={!model.context.orderDocument}
                            LinkComponent={MyButtonLinkNewTab}
                            IconLeft={Icons.File}
                            fullWidth={breakpoints.isVerySmallOnly}
                        />
                    ) : (
                        <MyButton
                            label="Review Order"
                            buttonType="Primary"
                            disabled={model.isReadOnly || totalQuantity === 0}
                            onClick={() => setShowReviewModal(true)}
                            IconLeft={breakpoints.isVerySmallOnly ? undefined : Icons.Play}
                            fullWidth={breakpoints.isVerySmallOnly}
                        />
                    )}
                </div>
            )}

            {showEditModal && (
                <PurchaseOrderEditModal
                    order={model}
                    close={() => setShowEditModal(false)}
                />
            )}

            {showReviewModal && (
                <PurchaseOrderReviewModal
                    model={model}
                    windows={windows}
                    close={() => setShowReviewModal(false)}
                />
            )}
        </div>
    );
}

function LinkToSalesOrder({ model }: { model: PurchaseOrderDetail }) {
    if (model.context.parentOrder?.isSalesOrder) {
        // Purchase orders link to sales order if it came from a quote
        // No link is shown if it came from a draft order
        return (
            <Link
                className="Link PurchaseOrderDetailTab__StatusBadges__OrderLink"
                to={`/sales-orders/${model.context.parentOrder.id}`}
            >
                View sales order
            </Link>
        );
    }
    return null;
}

function ArchivedBadge() {
    return <div className="PurchaseOrderDetailTab__ArchivedBadge">Archived</div>;
}

export function ProductList({
    order,
    windows,
    isReadOnly,
}: {
    order: PurchaseOrderDetail;
    windows: OrderWindow[];
    isReadOnly?: boolean;
}) {
    const [filteredProducts, setFilteredProducts] = useState<OrderWindowProduct[]>();
    const [showAddProductModal, setShowAddProductModal] = useState(false);

    const breakpoints = useBreakpoints();

    const allProducts = useMemo(
        () =>
            windows
                ?.flatMap(w => w.window_products)
                .filter(p => !!p.is_selected)
                .sort(orderProductSort) ?? undefined,
        [windows],
    );

    const labelOptions = useProductGroupOptions(order.id);
    const filters: DataTableFilterConfig[] = useMemo(() => {
        return [
            {
                label: 'Search',
                type: 'search',
                fields: ['master_products_name', 'products_name', 'details'],
                applyFilter: (filter, value, item: OrderWindowProduct) => {
                    const fields = [
                        item.master_products_name ? 'master_products_name' : 'products_name',
                        'details',
                    ];
                    return SearchFilter.applyFilter(
                        {
                            ...filter,
                            type: 'search',
                            fields,
                        },
                        value,
                        item,
                    );
                },
            },
            {
                label: 'Filter',
                type: 'select',
                field: 'filter',
                options: [
                    {
                        label: 'All products',
                        value: 'all',
                    },
                    {
                        label: 'With errors',
                        value: 'problems',
                    },
                ],
                defaultValue: 'all',
                allowBlank: false,
                applyFilter: (filter, value, item) => {
                    if (value === 'problems') {
                        const errors = validateProducts([item]);
                        return errors.length > 0;
                    }
                    return true;
                },
            },
            {
                label: 'Window or group',
                type: 'select',
                field: 'details',
                options: [
                    { label: '(None)', value: '__NO_LABEL', isPlaceholder: true },
                    ...labelOptions,
                ],
                defaultValue: '',
                allowBlank: true,
                applyFilter: (filter, value, item: OrderWindowProduct) => {
                    if (value === '__NO_LABEL') {
                        return !item.details;
                    }
                    return item.details?.startsWith(value) ?? false;
                },
            },
        ];
    }, [labelOptions]);

    return (
        <div className="PurchaseOrderDetailTab__ProductList">
            {allProducts.length === 0 ? (
                <div className="PurchaseOrderDetailTab__ProductList__Empty">
                    {isReadOnly ? (
                        <label>No products added</label>
                    ) : (
                        <>
                            <label>No products added yet</label>
                            <MyButton
                                label="Add product"
                                IconLeft={Icons.Plus}
                                buttonType="Accent"
                                onClick={() => setShowAddProductModal(true)}
                            />
                        </>
                    )}
                </div>
            ) : (
                <>
                    <div className="PurchaseOrderDetailTab__ProductList__Header">
                        <h2>Products</h2>
                        <div className="PurchaseOrderDetailTab__ProductList__Header__Actions">
                            {!isReadOnly && (
                                <MyButton
                                    className="PurchaseOrderDetailTab__ProductList__Toolbar__AddButton"
                                    label={'Add'}
                                    IconLeft={Icons.Plus}
                                    buttonType="Accent"
                                    onClick={() => setShowAddProductModal(true)}
                                    fullWidth={breakpoints.isVerySmallOnly}
                                />
                            )}
                        </div>
                    </div>
                    <div className="PurchaseOrderDetailTab__ProductList__Toolbar">
                        <DataTableFilters
                            filters={filters}
                            data={allProducts}
                            onChange={setFilteredProducts}
                        />
                    </div>
                    {(filteredProducts?.length ?? 0) === 0 ? (
                        <div className="PurchaseOrderDetailTab__ProductList__ProductsFilteredOut">
                            No products in this order matched your search.
                        </div>
                    ) : (
                        <div className="PurchaseOrderDetailTab__ProductList__Products">
                            {filteredProducts?.map(p => (
                                <OrderProductRow
                                    key={p.id}
                                    order={order}
                                    product={p}
                                    showManufacturerStatus={true}
                                />
                            ))}
                        </div>
                    )}
                </>
            )}

            {showAddProductModal && (
                <OrderProductAddModal
                    orderId={order.id}
                    windows={windows}
                    manufacturerId={order.manufacturerId}
                    close={() => setShowAddProductModal(false)}
                />
            )}
        </div>
    );
}
